
export default {
    name: 'medianet',
    init() {
        let enableHeaderBidding = profads.ads2.enableHeaderBidding;
        if (enableHeaderBidding) enableHeaderBidding = ['mn'];

        var headerBiddingFunctions = {
            'mn': 'loadMediaHeaderBidding'
        };

        window.loadMediaHeaderBidding = () => {
            window.advBidxc = window.advBidxc || {};
            // enable refresh across the board. set window.advBidxc.misc.aref = false; to disable
            window.advBidxc.misc = window.advBidxc.misc || {};
            // check if current page has ctype from the cTypes array, if true then do not refresh
            let cTypes = [10514, 20152];
            const hasCtypes = cTypes.filter( c => {
                return c === DFPTargetKeys.pageSegVars.ctype;
            });

            if ((window.isEmed || window._isAnArticle) && DFPTargetKeys.reqHeaders.requestEnv !== 'staging') { // when needed, add back in check for  && hasCtypes.length === 0
                window.advBidxc.misc.aref = true;
            } else {
                window.advBidxc.misc.aref = false;
            }

            window.advBidxc.renderAd = function () {
            };
            window.advBidxc.customerId = "8CU5I56G3"; //Media.net Customer ID
            window.advBidxc.domain = window.location.hostname;
            window.advBidxc.version = 5.1;
            window.advBidxc.startTime = new Date().getTime();
            window.advBidxc.isAdServerLoaded = true;

            var isSSL = true;
            var mnSrc = 'https://hbx.media.net/bidexchange.js?cid=' + window.advBidxc.customerId + '&version=' + window.advBidxc.version + (isSSL ? '&https=1' : '');
            profads.ads2.utils.loadScript({
                src: mnSrc,
                handler: this.mediaNetOnloadHandler,
                async: true,
                onerror() {
                    console.log('%c name', 'background: red; color: #fff;', 'error loading media net script');
                }
            });
        };

        if (window.top === window.self && enableHeaderBidding !== false) {
            if (enableHeaderBidding !== false && (typeof enableHeaderBidding === "object" && enableHeaderBidding.length > 0)) {
                var adserverCalled = false;
                for (var i = 0; i < enableHeaderBidding.length; i++) {
                    if (typeof headerBiddingFunctions[enableHeaderBidding[i]] !== "undefined" && typeof window[headerBiddingFunctions[enableHeaderBidding[i]]] !== "undefined") {
                        window[headerBiddingFunctions[enableHeaderBidding[i]]].call();
                        adserverCalled = true;
                    }
                }
                if (adserverCalled === true) enableHeaderBidding = true;
            }
        }
    },
    mediaNetOnloadHandler() {
        // do something on load if needed
    }
};
