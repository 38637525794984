// Meads ipon global variables
window.oldPositions = {};
window.oldMEpos = [];
window.enableME = true;
//end of Meads 

// Check for PageMetadata / DFPTargetKeys object
if (typeof window.PageMetadata == 'undefined' && typeof window.DFPTargetKeys == 'object') {
    window.PageMetadata = window.DFPTargetKeys;
}


// start of profads variables

window.profads = window.profads || {};
/* Variable for the Current Page URL as we use this data many times */
window.pageCurrentURL = window.location.href.toLowerCase();
window.s_pageview_id;
window.AjaxTargetKeys;
window.slotCount = 0;
window.findDmp = 0;
if (typeof window.locale === "undefined") {
    window.locale = "us";
}
// is the current page an article regardless of the language
window._isAnArticle = pageCurrentURL.match(/(viewarticle|artikelansicht|voirarticle|verarticulo|verartigo)/) ? true : false;
//is the current page is Recap
window._isRecap = ['resumindo', 'resena', 'kurzzusammenfassung', 'recap'].filter(x => {
    return window.pageCurrentURL.indexOf(x) !== -1 ? true : false;
}).length == 0 ? false : true;
// For Emedicine domain
window.isEmed = ($.inArray(window.PageMetadata.pageSegVars.cg, ["502", "503", "504", "505", "508"]) != -1);
// dd = drug and disease aka emedicine and reference
window.isDD = ($.inArray(window.PageMetadata.pageSegVars.cg, ["501", "502", "503", "504", "505", "508"]) != -1);

window._hostName = window.location.hostname.split(".")[0], window._isODP = window._hostName === "odp" || window._hostName === "decisionpoint";
//FFQ check using Ctype.
window.ffqCtype = [20152,49128,330137,650135,590111];
window.getCtype = window.PageMetadata.pageSegVars.ctype;
window._isFFQ = window.ffqCtype.indexOf(getCtype) != -1 ? true : false;

if(window.breakpoint == 1){
    if((window._hostName == "www") && (window._isAnArticle)){
        window.mobOptCheck = true;
    }else if(window.isEmed && ($.inArray(window.PageMetadata.pageSegVars.ssp, ["7", "22", "26", "27"]) != -1)){
        window.mobOptCheck = true;
    }else{
        window.mobOptCheck = false;
    }
}

if(window.mobOptCheck == true){
    window.textDriverOptimized = window.mobOptCheck;
}
//end FFQ check using Ctype.

// PPE-51921 - true enables swap rules for 1520 and 1122
window.bUse_1520_1122_SwapRules = (window.isEmed || window.pageCurrentURL.indexOf('viewarticle') !== -1 || PageMetadata.webSegVars.pc === 'hp') && PageMetadata.reqHeaders.device == "MOBILE" && (locale == 'us') && true;

/* HACK to block interactive quiz from 1520 and 1122 swap */
window.xmlConTyp = $('meta[name=xml-type]').attr("content");

if (window.xmlConTyp == "interactive quiz" || "case challenge" || "article") {
    window.bUse_1520_1122_SwapRules = false;
}

/* (END) HACK to block interactive quiz from 1520 and 1122 swap */


/**
 * Prof Ads framework
 */
if (typeof window.s_responsive_design === 'undefined') {
    window.s_responsive_design = false;
}
if (window.s_responsive_design) {
    if (window.isDD) {
        window.bUse_1520_1122_SwapRules = false;
    }
}

// end of profads variables