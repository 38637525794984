const scrollInstreamRecircEvents = {
    init: function (){
        var scrollTimeRecirc;
        $(window).on("scroll.lazyLoadInstreamRecirc", function () {
            if($('#instream-recirc').length > 0){
                    clearTimeout(scrollTimeRecirc);
                scrollTimeRecirc = setTimeout(scrollInstreamRecircEvents.lazyLoadInstreamRecirc(), 250);
            }
        });
    },
lazyLoadInstreamRecirc: function () {
    if (scrollEvents.scrollIntoView('#instream-recirc') == true) {
            if(!window.scrollInstreamRecirc){
                //$('#ads-pos-1420').show();
                //console.log("inside lazyLoadInstreamRecirc ",window.scrollInstreamRecirc);
                setTimeout(function(){
                    window.ads2_ignore["916"] = false;
                    window.ads2_ignore["917"] = false;
                    window.scrollInstreamRecirc = true;
                    webmd.ads2.setPageTarget('ll', '1');
                    webmd.ads2.defineAd({id: 'ads-pos-916', pos: 916});
                    webmd.ads2.defineAd({id: 'ads-pos-917', pos: 917});
                    webmd.ads2.refresh({pos:{916:true,917:true}});

                },500);
                $(window).off("scroll.lazyLoadInstreamRecirc");
            }
        }
    }
}

export {scrollInstreamRecircEvents as default};