function staticTargetKeys() {
   //lif key - default case value
   webmd.ads2.setPageTarget('lif', 0); 
   //al key - default case
  webmd.ads2.setPageTarget("al", "0");
  //tug key default case
  webmd.ads2.setPageTarget("tug", "0");
  //rd key
  var rd = window.s_responsive_design ? 1 : 0;
  webmd.ads2.setPageTarget("rd", rd);
  //geoc key
  let geoc =
    PageMetadata.reqHeaders.geoc === null ||
    PageMetadata.reqHeaders.geoc === undefined
      ? "0"
      : PageMetadata.reqHeaders.geoc;
  webmd.ads2.setPageTarget("geoc", geoc);
  //pvb key
  let pvbVal = typeof isMscpApp !== "undefined" && isMscpApp ? "app" : "web";
  webmd.ads2.setPageTarget("pvb", pvbVal);
  //acs key
  if (typeof s_auth_channel_id != "undefined") {
    webmd.ads2.setPageTarget("acs", s_auth_channel_id);
  } else {
    webmd.ads2.setPageTarget("acs", 0);
  }
  //cn key
  webmd.ads2.setPageTarget("cn", "1");
  
  //sname key
  if (typeof s_md !== "undefined" && s_md != null && s_md.prop3) {
    webmd.ads2.setPageTarget("sname", s_md.prop3);
  }
}

export { staticTargetKeys as default };
