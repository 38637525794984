const url = new URL(document.location.href);
const { searchParams } = url;
const debugParam = searchParams.get("ads-debug");
const allowLog = debugParam != null;
const consolePrefix = "[Ads]";

class DebugLog {
    constructor() {
        this.allowLog = allowLog; 
    }
    _log(method, ...args) {
        if (this.allowLog) {
            args[0] = `${consolePrefix} ${args[0]}`; 
            console[method].apply(console, args);  
        }
    }

    error(...args) {
        this._log('error', ...args); 
    }

    log(...args) {
        this._log('log', ...args); 
    }

    warn(...args) {
        this._log('warn', ...args); 
    }
}

const debug = new DebugLog();
export { debug };